<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <validation-observer v-slot="{ handleSubmit }">
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-card>
          <v-card-title primary-title />
          <v-card-text>
            <!--username and password-->
            <v-row class="mt-10">
              <v-col cols="6">
                <v-row>
                  <v-col
                    class="text-button align-self-center pt-0 mb-9"
                    cols="3"
                  >
                    <div>
                      <span class="font-weight-bold">{{
                        $t('messages.username')
                      }}</span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <div>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-text-field
                          id="username"
                          v-model="username"
                          readonly
                          name="username"
                          outlined
                          :error-messages="errors[0]"
                        />
                      </validation-provider>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="userType == 1">
                  <v-col
                    class="text-button align-self-center pt-0"
                    cols="3"
                  >
                    <div>
                      <span class="font-weight-bold">{{
                        $t("messages.password")
                      }}</span>
                    </div>
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      color="primary"
                      @click="resetPass"
                    >
                      {{ $t("button.resetPassword") }}
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="3"
                    class="text-button align-self-center pb-0 pt-0"
                  >
                    <v-text-field
                      ref="newPass"
                      v-model="oneTimePass"
                      name="oneTimePass"
                      :type="showPass ?'text':'password'"
                      :append-icon="showPass ?'mdi-eye':'mdi-eye-off'"
                      outlined
                      readonly
                      @click:append="showPass=!showPass"
                      @focus="$event.target.select()"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      v-clipboard:copy="oneTimePass"
                      v-clipboard:success="onSuccess"
                      v-clipboard:error="onError"
                    >
                      {{ alertCopy.text }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-12">
              <v-col
                v-for="(value, index) in fields"
                :key="index"
                class="align"
                cols="6"
              >
                <v-row>
                  <v-col
                    v-if="value.type !== 'spacer'"
                    class="text-button align-self-center pt-0 mb-9"
                    cols="3"
                  >
                    <div>
                      <span class="font-weight-bold">{{
                        $t('messages.' + value.label)
                      }}</span>
                      <span class="red--text">
                        {{
                          value.validate
                            ? value.validate.includes('required')
                              ? '*'
                              : ''
                            : ''
                        }}</span>
                    </div>
                  </v-col>
                  <v-col
                    class="text-button align-self-center pb-0 pt-0"
                    cols="7"
                  >
                    <v-textarea
                      v-if="value.type === 'textarea'"
                      v-model="value.value"
                      clearable
                      outlined
                    />
                    <validation-provider
                      v-else-if="value.type === 'date-text'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <div v-if="'menu' in value">
                        <v-row>
                          <v-col cols="10">
                            <v-text-field
                              v-model="value.value"
                              :name="value.key"
                              :disabled="value.disabled"
                              clearable
                              outlined
                              autocomplete="off"
                              :error-messages="errors[0]"
                              :placeholder="$t(value.placeholder)"
                              @keydown="checkDate"
                            >
                              <template v-slot:append />
                            </v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-menu
                              :ref="'menu' + value.key"
                              v-model="value.menu"
                              :close-on-content-click="false"
                              :return-value.sync="value.value"
                              transition="scale-transition"
                              offset-y
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="mt-4 mr-3"
                                  v-bind="attrs"
                                  left
                                  v-on="on"
                                >
                                  mdi-calendar
                                </v-icon>
                              </template>
                              <v-date-picker
                                v-model="value.value"
                                locale="ja-jn"
                                no-title
                                scrollable
                                :max="value.max_date ? value.max_date : ''"
                              >
                                <v-spacer />
                                <v-btn
                                  text
                                  color="primary"
                                  @click="value.menu = false"
                                >
                                  {{ $t('messages.cancel') }}
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs['menu' + value.key][0].save(
                                      value.value
                                    )
                                  "
                                >
                                  {{ $t('messages.ok') }}
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </div>
                      <div v-else>
                        <v-text-field
                          v-model="value.value"
                          :name="value.key"
                          clearable
                          :disabled="value.disabled"
                          outlined
                          :error-messages="errors[0]"
                          :placeholder="$t(value.placeholder)"
                          @keydown="checkDate"
                        />
                      </div>
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'select'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-select
                        v-if="value.key === 'position'"
                        v-model="value.value"
                        :disabled="value.disabled"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0]"
                        :items="listPositions"
                        :placeholder="$t(value.placeholder)"
                      />
                      <v-select
                        v-else
                        v-model="value.value"
                        :disabled="value.disabled"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0]"
                        :items="value.items"
                        item-text="text"
                        item-value="value"
                        :placeholder="$t(value.placeholder)"
                      />
                    </validation-provider>
                    <validation-provider
                      v-else-if="value.type === 'text'"
                      v-slot="{ errors }"
                      :name="value.key"
                      :rules="value.validate ? value.validate : ''"
                    >
                      <v-text-field
                        v-model="value.value"
                        :disabled="value.disabled"
                        :name="value.key"
                        outlined
                        :error-messages="errors[0]"
                        clearable
                        :placeholder="$t(value.placeholder)"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!--others-->

            <!--button-->
            <v-row align="center" />
          </v-card-text>
          <v-card-actions />
        </v-card>
        <v-row class="d-flex justify-end mt-3">
          <v-col
            cols="2"
            class="d-flex justify-space-between"
          >
            <v-btn
              large
              block
              color="info"
              type="submit"
            >
              <span class="text-h5 pr-3 pl-3"> {{ $t('messages.save') }}</span>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              large
              block
              color="info"
              @click="openDialog()"
            >
              <span class="text-h5 pr-3 pl-3">
                {{ $t('messages.delete') }}</span>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              large
              block
              color="info"
            >
              <span
                class="text-h5 pr-3 pl-3"
                @click="$router.push('/employee-list/')"
              >
                {{ $t('messages.close') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="font-weight-bold text-h4">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="1">
              <v-icon
                large
                color="blue darken-2"
              >
                mdi-delete-outline
              </v-icon>
            </v-col>
            <v-col cols="8">
              この操作は元に戻りません。
              本当に削除してよろしいですか？
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-spacer />

          <v-btn
            class="text-h5"
            dark
            color="#E43E08"
            @click="deleteItem"
          >
            {{ $t('messages.delete') }}
          </v-btn>
          <v-btn
            class="text-h5"
            @click="dialogDelete = false"
          >
            {{ $t('messages.cancel') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// Utilities

  import { get } from 'vuex-pathify'
  import moment from 'moment'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { required, email, min } from 'vee-validate/dist/rules'
  import _ from 'lodash'
  export default {
    name: 'EmployeeEdit',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        username: '',
        snackbarMessage: '',
        snackbar: false,
        dialogDelete: false,
        snackbarCloseInerval: null,
        rePasswordCheck: '',
        fields: [
          {
            label: 'agencyIdEmployee',
            key: 'agencyId',
            value: '',
            type: 'text',
            validate: 'required',
            disabled: true,
          },
          {
            label: 'dob',
            key: 'dob',
            value: '',
            type: 'date-text',
            max_date: moment().format('YYYY-MM-DD'),
            validate: 'required|date|rangeDate',
            placeholder: 'placeholder.dob',
            menu: false,
            disabled: false,
          },
          {
            key: 'position',
            label: 'position',
            type: 'select',
            value: 7,
            validate: 'required',
            disabled: false,
          },
          {
            label: 'sex',
            key: 'sex',
            items: [
              { value: 1, text: '男' },
              { value: 0, text: '女' },
            ],
            value: '',
            type: 'select',
            validate: 'required',
            disabled: false,
          },
          {
            label: 'name',
            key: 'name',
            value: '',
            type: 'text',
            validate: 'required',
            placeholder: 'placeholder.name',
            disabled: false,
          },
          {
            label: 'email',
            key: 'email',
            value: '',
            type: 'text',
            validate: 'required|email',
            placeholder: 'placeholder.email',
            disabled: false,
          },
          {
            label: 'furiganaName',
            key: 'furiganaName',
            value: '',
            type: 'text',
            validate: 'required',
            placeholder: 'placeholder.furiganaName',
            disabled: false,
          },
          {
            label: 'spacer',
            key: 'spacer',
            value: '',
            type: 'spacer',
            disabled: false,
          },
          {
            label: 'contactPhone',
            key: 'contactPhone',
            value: '',
            type: 'text',
            validate: 'required|phone',
            placeholder: 'placeholder.contactPhone',
            disabled: false,
          },
          {
            label: 'spacer',
            key: 'spacer',
            value: '',
            type: 'spacer',
            disabled: false,
          },
          {
            label: 'contactAddress',
            key: 'contactAddress',
            value: '',
            type: 'textarea',
            placeholder: 'placeholder.contactAddress',
            disabled: false,
          },
        ],
        userType: 0,
        alertCopy: {
          text: this.$t('button.copy'),
        },
        showPass: false,
        oneTimePass: '',
      }
    },
    computed: {
      ...get('employee', ['employeeDetail', 'message', 'status', 'error', 'newPassword', 'resetPassword', 'listPositions']),
    },
    watch: {
      message (value) {
        if (value === 'success') {
          this.showSnackBar('messages.success')
          this.$router.push({ path: '/employee-list', query: { success: true } })
        } else if (value === 'successChangePass') {
          this.showSnackBar('messages.successChangePass')
        } else if (value === 'deleteSuccess') {
          this.$router.push({ path: '/employee-list', query: { deleteSuccess: true } })
        } else if (value === 'updateSuccess') {
          this.$router.push({ path: '/employee-list', query: { updateSuccess: true } })
        }
      },
      employeeDetail (value) {
        this.username = value.username

        for (let [key, val] of Object.entries(value)) {
          const field = this.fields.find(o => o.key === key)
          if (field) {
            if (key === 'dob') {
              if (val) {
                val = moment(val).format('YYYY-MM-DD')
              }
            }
            if (key === 'sex') {
              val = val ? 1 : 0
            }
            field.value = val
          }
        }
        this.oneTimePass = this.newPassword
      },
      resetPassword (value) {
        if (value.success === 1) {
          this.oneTimePass = value.data.newPassword
        }
      },
      error (value) {
        this.showSnackBar(value)
      },

      rePassword (value) {
        this.rePasswordCheck =
          value === this.password
            ? ''
            : this.$t('validator.confirm_password_invalid')
      },
    },
    created () {
      if (localStorage.getItem('user')) {
        this.userType = JSON.parse(localStorage.getItem('user')).detail.type
      }
      this.$store.dispatch('employee/getEmployeeDetail', {
        id: this.$route.params.id,
      })
    },
    mounted () {
      // Define the rule globally
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })
      extend('email', {
        ...email,
        message: this.$t('validator.email_invalid'),
      })
      extend('min', {
        ...min,
        message: this.$t('validator.password_invalid'),
      })
      extend('date', {
        validate (value) {
          return /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(value)
        },
        message: this.$t('生年月日は正確な形式で入力してください。'),
      })
      extend('passwordRegex', {
        validate (value) {
          return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value)
        },
        message: '英大文字・英小文字・数字を組み合わせて8文字以上で入力してください',
      })
      extend('phone', {
        validate (value) {
          return /^[+]?[(]?[0-9]{4}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,5}$/.test(value)
        },
        message: this.$t('数字のみ入力してください。'),
      })
      extend('rangeDate', {
        validate (value) {
          if (moment(value).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')) return false
          return true
        },
        message: this.$t('生年月日は過去の日付である必要があります'),
      })
    },
    methods: {
      showSnackBar (message) {
        this.snackbarMessage = this.$t(message)
        this.snackbar = true
        if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
        this.snackbarCloseInerval = setTimeout(() => {
          this.snackbar = false
        }, 5000)
      },
      onSuccess () {
        this.alertCopy.text = this.$t('messages.copied')
      },
      onError () {
        this.alertCopy.text = this.$t('messages.uncopy')
      },
      onSubmit () {
        const payload = {
          name: this.fields.find(o => o.key === 'name').value,
          dob: this.fields.find(o => o.key === 'dob').value,
          sex: this.fields.find(o => o.key === 'sex').value,
          position: this.fields.find(o => o.key === 'position').value,
          furiganaName: this.fields.find(o => o.key === 'furiganaName').value,
          email: this.fields.find(o => o.key === 'email').value,
          contactPhone: this.fields.find(o => o.key === 'contactPhone').value,
          contactAddress: this.fields.find(o => o.key === 'contactAddress').value,
          contactInfo: '123',
          userId: this.$route.params.id,
        }
        this.$store.dispatch('employee/update', payload)
      },
      deleteItem () {
        const payload = {
          userId: this.$route.params.id,
        }
        this.$store.dispatch('employee/delete', payload)
      },
      openDialog () {
        this.dialogDelete = true
      },
      checkDate (evt) {
        const field = this.fields.find(o => o.key === 'dob')
        if (
          (evt.keyCode >= 48 && evt.keyCode <= 57) ||
          (evt.keyCode >= 96 && evt.keyCode <= 105)
        ) {
          evt = evt || window.event
          if (field.value) {
            var size = field.value.length
            if (size >= 10) field.value = field.value.slice(0, -1)
            if (
              (size === 4 && field.value > moment().year()) ||
              (size === 7 && Number(field.value.split('-')[1]) > 12) ||
              (size === 10 && Number(field.value.split('-')[2]) > 31)
            ) {
              field.value = ''
              // add alert funct
              return
            }

            if (
              (size === 4 && field.value < moment().year()) ||
              (size === 7 && Number(field.value.split('-')[1]) < 13)
            ) {
              field.value += '-'
            }
          }
        }
      },
      resetPass () {
        const payload = {
          id: this.employeeDetail.id,
        }
        this.$store.dispatch('employee/resetPassword', payload)
      },
    },
  }
</script>
